import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import DriversPanel from "./DriversPanel";
import RegisterDriver from "./RegisterDriver";
import Login from "./Login";
import AudioPlayer from "./AudioPlayer";
import "./App.css";
import LoadMap from "./LoadMap";
function App() {
  return (
    <div>
      {/* <AudioPlayer /> */}
      <BrowserRouter>
        <LoadMap />
        <Routes>
          <Route path="/" element={<DriversPanel />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<RegisterDriver />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
