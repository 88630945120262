import React, { useState } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";

const DropdownMenu = ({ index }) => {
  let Navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.target);
  };

  const handleMenuClose = (event) => {
    console.log(event.target.textContent);
    let Text = event.target.textContent;
    if (Text == "Logout") {
      localStorage.clear();
      Navigate("/login");
      index = 0;
    } else {
      // localStorage
    }
    setAnchorEl(null);
  };

  return (
    <div style={{ marginTop: "10px" }}>
      <IconButton
        edge="end"
        color="inherit"
        aria-label="menu"
        onClick={handleMenuClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleMenuClose}>Logout</MenuItem>
        <MenuItem onClick={handleMenuClose}>Settings</MenuItem>
        <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
        <MenuItem onClick={handleMenuClose}>Emergency</MenuItem>
      </Menu>
    </div>
  );
};

export default DropdownMenu;
