import React, { useEffect } from "react";
import { ConsumeContext } from "./StateProvider";
import { Typography } from "@mui/material";
let index = 0;
function LoadMap() {
  let { openSnackBar, setopenSnackBar, lat, setlat, lng, setlng } =
    ConsumeContext();
  useEffect(() => {
    getCurrentLocation();
    index = 0;
  }, []);
  const getCurrentLocation = async () => {
    const showError = (error) => {
      switch (error.code) {
        case error.PERMISSION_DENIED:
          // alert("Please turn on and grant permission your location.");
          setopenSnackBar({
            Message: (
              <Typography variant="contained" color={"error"}>
                Please turn on your location and grant permissions.
              </Typography>
            ),
            Open: true,
          });
          break;
        case error.POSITION_UNAVAILABLE:
          alert("Location information is unavailable.");
          break;
        case error.TIMEOUT:
          alert("The request to get user location timed out.");
          break;
        case error.UNKNOWN_ERROR:
          alert("An unknown error occurred.");
          break;
      }
    };
    const showPosition = (position) => {
      const { latitude, longitude } = position.coords;
      setlng(longitude);
      setlat(latitude);
    };
    if ("geolocation" in navigator) {
      console.log("Geolocation is supported by this browser.");

      if (navigator.permissions) {
        navigator.permissions
          .query({ name: "geolocation" })
          .then((permissionStatus) => {
            if (permissionStatus.state === "granted") {
              console.log("Location services are enabled.");
              // Proceed with finding the nearest taxi
            } else if (permissionStatus.state === "prompt") {
              console.log("Location permission prompt is shown.");
              // You can choose to handle this case differently, based on your application's needs
            } else if (permissionStatus.state === "denied") {
              console.log("Location services are disabled.");
              // You can inform the user that location services are disabled and provide guidance on enabling them
              setopenSnackBar({
                Message: (
                  <Typography variant="contained" color={"error"}>
                    {" "}
                    Please turn on your location
                  </Typography>
                ),
                Open: true,
              });
            }
          });
      } else {
        console.log(
          "Permission API is not supported. Cannot determine location permission status."
        );
        // Handle the case where the browser does not support the Permissions API
      }
    } else {
      alert("Geolocation is not supported by this browser.");
      console.log("Geolocation is not supported by this browser.");
      // Handle the case where geolocation is not supported
    } //////////////////////
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, showError);
    } else {
      console.log("Geolocation is not supported by this browser application.");
    }
  };
  useEffect(() => {
    const loadGMap = async () => {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_API_KEY}&callback=initMap`;
      script.id = "DriversMapScript";
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    };

    if (lat != "" && lng != "") {
      let DriversMapScript = document.getElementById("DriversMapScript");
      if (DriversMapScript) {
        window.initMap();
      } else loadGMap();
    }
  }, [lng, lat]);
  window.initMap = () => {
    const map = new window.google.maps.Map(document.getElementById("map"), {
      center: { lat, lng },
      zoom: 17,
      mapTypeId: "roadmap",
    });

    let guzoMarker = new window.google.maps.Marker({
      animation: window.google.maps.Animation.BOUNCE,
      position: { lat, lng },
      map: map,
      title: "Marker",
    });
    let infowindow = new window.google.maps.InfoWindow({
      content: "you are here!",
    });

    infowindow.open(map, guzoMarker);
    // checkDriversStatus();
  };
  return (
    <div>
      <div style={{ height: "98vh", width: "100%" }} id="map"></div>
    </div>
  );
}

export default LoadMap;
