import { Alert, Snackbar } from "@mui/material";
import React from "react";
import { ConsumeContext } from "./StateProvider";

function MySnackBar() {
  // alert(openSnackBar.Open);
  let { openSnackBar, setopenSnackBar } = ConsumeContext();
  console.log("openSnackBar", openSnackBar);
  let handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setopenSnackBar((revState) => {
      return { ...revState, Open: false };
    });
  };
  return (
    <Snackbar
      sx={{
        position: "absolute",
        maxWidth: "300px",
        width: "80%",
        margin: "auto",
        bottom: "250px",
      }}
      open={openSnackBar.Open}
      autoHideDuration={3000}
      onClose={handleSnackClose}
    >
      <Alert
        onClose={handleSnackClose}
        severity="success"
        color="success"
        sx={{ width: "100%" }}
      >
        {openSnackBar.Message}
      </Alert>
    </Snackbar>
  );
}

export default MySnackBar;
