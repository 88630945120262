import React, { useState } from "react";
import { TextField, Button, Box, Typography } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ConsumeContext } from "./StateProvider";
let Path = process.env.REACT_APP_PATH;
const RegistrationForm = () => {
  const [sixDigitNumber, setSixDigitNumber] = useState();
  const [Processing, setProcessing] = useState(false);
  let Navigate = useNavigate();
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [RegistrationData, setRegistrationData] = useState({
    token: null,
    userProfile: null,
  });
  const [registrationError, setregistrationError] = useState(null);
  const handleDriverRegistrationSubmit = async (event) => {
    event.preventDefault();

    try {
      setProcessing(true);
      await axios
        .post(Path + "/registerDrivers", {
          fullName,
          email,
          phone,
        })
        .then((Responces) => {
          setProcessing(false);
          console.log("data", Responces.data);
          // return;
          setProcessing(false);
          let { token, data, userProfile } = Responces.data;
          console.log("token", token, "Path", Path);
          if (data == "email or phone is reserved before") {
            setregistrationError(
              <h4 style={{ color: "red" }}>This {" " + data}</h4>
            );
            return;
            // alert("data");
          } else if (data == "login success") {
            // setFullName("");
            // setEmail("");
            // setPhone("");
            // localStorage.setItem("userProfile", JSON.stringify(userProfile[0]));
            // localStorage.setItem("token", token);
            setRegistrationData({ token, userProfile: userProfile[0] });
            setregistrationError(null);
            setShowPhoneNumbersVerificationForm(true);
            // in development test mode it is open but in production verification is mandatory
            // Navigate("/");
          }
        })
        .catch((error) => {
          setProcessing(false);
          console.log("error", error);
        });

      // console.log("User registration successful:", response.data);
      // Reset form fields
    } catch (error) {
      console.error("Error registering user:", error);
    }
  };
  let { openSnackBar, setopenSnackBar } = ConsumeContext();
  const [
    ShowPhoneNumbersVerificationForm,
    setShowPhoneNumbersVerificationForm,
  ] = useState(false);
  let SubmitPhoneNumberRealityVerification = (e) => {
    e.preventDefault();
    let data = {
      sixDigitNumber,
      token: RegistrationData.token,
    };
    console.log("data is ====", data);
    // return;
    axios
      .post(Path + "/verifyDriversRegistrationByPhoneNumber", data)
      .then((Responces) => {
        console.log(Responces.data);
        let { data } = Responces.data;
        if (data == `Correct Verification.`) {
          let { token, userProfile } = RegistrationData;
          // 82128;
          // console.log("token== ", token, "userProfile===", userProfile);
          localStorage.setItem("token", token);
          localStorage.setItem("userProfile", JSON.stringify(userProfile));
          Navigate("/");
          // alert("correct code");
        } else if (data == "Wrong Verificattion.") {
          setregistrationError(
            "You have inserted wrong pincode. please verify again"
          );
        } else {
          setregistrationError("Unknown errors");
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  return (
    <Box
      sx={{
        position: "absolute",
        bottom: "60px",
        left: "50%",
        transform: "translateX(-50%)",
        zIndex: 1,
        backgroundColor: "white",
        padding: "15px 30px",
        width: "100%",
        maxWidth: "280px",
      }}
    >
      {openSnackBar.Message}
      {/* <Paper
        elevation={3}
        sx={{ width: `fit-content`, padding: "20px", margin: `20px auto ` }}
      >
        {" "}
      </Paper> */}
      <div style={{ textAlign: "center" }}>Registration Form To Drivers</div>
      {!ShowPhoneNumbersVerificationForm ? (
        <form
          style={{ maxWidth: "300px" }}
          onSubmit={handleDriverRegistrationSubmit}
        >
          <TextField
            label="Full Name"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <Box sx={{ mt: 2 }}>
            {!Processing ? (
              <Button
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
              >
                Register
              </Button>
            ) : (
              <Button disabled variant="contained">
                Processing ...{" "}
              </Button>
            )}
          </Box>
        </form>
      ) : (
        <form onSubmit={SubmitPhoneNumberRealityVerification}>
          <h5>
            We sent to you 6 digit numbers by sms. please check your phone and
            fill it in the form. Thank you
          </h5>

          <TextField
            value={sixDigitNumber}
            onChange={(e) => {
              const input = e.target.value;
              const regex = /^\d{0,6}$/; // Regular expression to allow only up to 6 digits

              if (regex.test(input)) {
                setSixDigitNumber(input);
              }
            }}
            type="number"
            sx={{ margin: "20px 0", width: "100%" }}
            label="Enter  6 digit number"
          />
          <Button fullWidth variant="contained" type="Submit">
            Submit
          </Button>
        </form>
      )}
      {registrationError && (
        <Typography color={"error"}>{registrationError}</Typography>
      )}
      <Box
        sx={{ color: "blueviolet", cursor: "pointer", padding: "20px 10px" }}
        onClick={() => {
          Navigate("/login");
        }}
      >
        If you have account, please click here to login.{" "}
      </Box>
    </Box>
  );
};

export default RegistrationForm;
// My Name is marew masresha abate and i am from Ethiopia. My job is teaching students in US America. Students learn Full-stack web development. when we search Free API to teach students we get netflix's document which provide free api and how to use. Then i integrate it and teach them in localhost. but they ask me how to host it in cpanel then i show them how to host in cpanel. but finally i get suspension from u.  Here i request u my code don't have attack or fraud but i use permitted API to teach students and if it is not allowed in your country law i am happy to remove netflix . so please give me back my domain masetawosha.com and others sub domain.   +251922112480 is my phone number and i am happy to receive messages in WhatsApp or telegram or direct call. Thank you.
