import React, { createContext, useContext, useState } from "react";

let InitialContext = createContext();

function StateProvider({ children }) {
  const [openSnackBar, setopenSnackBar] = useState({
    Open: false,
    Message: "",
  });
  const [lat, setlat] = useState("");
  const [lng, setlng] = useState("");

  return (
    <InitialContext.Provider
      value={{ openSnackBar, setopenSnackBar, lat, setlat, lng, setlng }}
    >
      {children}
    </InitialContext.Provider>
  );
}

export let ConsumeContext = () => useContext(InitialContext);
export default StateProvider;
