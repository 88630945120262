import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import DropdownMenu from "./DropdownMenu";
import MySnackBar from "./MySnackBar";
import { ConsumeContext } from "./StateProvider";
let PATH = process.env.REACT_APP_PATH;
let index = 0;
const DriversMapContainer = () => {
  let { openSnackBar, setopenSnackBar, lat, setlat, lng, setlng } =
    ConsumeContext();
  let Navigate = useNavigate();
  const [CurrentStatus, setCurrentStatus] = useState("Waitting...");
  const [token, settoken] = useState(localStorage.getItem("token"));
  const [DriversStatus, setDriversStatus] = useState({ Status: "Waitting..." });

  // it checkes if driver is in the status of acceptedByDriver or journeyStarted. if so it has to keep else show statr button

  let checkDriversStatus = async (data) => {
    try {
      const response = await axios
        .post(PATH + `/checkDriversStatus?token=${token}`, {
          lat,
          lng,
        })
        .then((Responces) => {
          let data = Responces.data.data,
            result = [];
          console.log("Responces", Responces);
          let status = DriversStatus.Status;
          console.log("status", status);
          if (index < 2) setCurrentStatus("status" + Math.random());
          if (Responces.data.result) {
            result = Responces?.data?.result[0];
          }

          if (data == "Driver can start job") {
            // update setDriversStatus only once
            console.log("index", index);
            if (index == 0) {
              setDriversStatus(() => {
                return { Status: data, result };
              });
              index++;
            }
          } else if (data == "Driver is with customer") {
            setDriversStatus({ Status: data, result });
          } else if (data == "login first") {
            alert("you haven't loged in. so please login first. Thank you.");
            Navigate("/login");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log("Error sending coordinates:", error);
    }
  };

  useEffect(() => {
    if (CurrentStatus !== "Waitting...")
      setTimeout(() => {
        console.log("CurrentStatus", CurrentStatus);
        checkDriversStatus();
      }, 3000);
  }, [CurrentStatus]);

  let checkIfPassangerRequestedTaxi = async () => {
    axios
      .post(PATH + "/checkIfPassangerRequestedTaxi", { token })
      .then((Responces) => {
        let { data, result } = Responces.data;
        console.log(Responces.data);
        if (data == "customer is available") {
          // console.log("DriversStatus.Status", DriversStatus.Status);
          setDriversStatus({ Status: data, result: result[0] });
          verifyIfCustomerIsNotWithOtherDriver(result[0]);
        } else if (data == "customer is not available") {
          console.log("DriversStatus.Status", DriversStatus.Status);
          if (index == 2)
            setTimeout(() => {
              checkIfPassangerRequestedTaxi();
            }, 3000);
          if (index == 0) {
            checkDriversStatus();
          }
        } else if (data == "Driver is with customer") {
          console.log("data", data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  let acceptCustomersCall = (customerInfo) => {
    // console.log(customerInfo);
    // console.log();

    axios
      .post(PATH + "/acceptCustomersCall", {
        token,
        guzoId: customerInfo.guzoId,
      })
      .then((Responces) => {
        console.log(Responces.data.data);
        let { data } = Responces.data;
        // return;
        if (data == "no data found") {
          // looking for customer...
          setDriversStatus((prevState) => {
            return {
              ...prevState,
              Status: "looking for customer...",
            };
          });
          alert("another driver may picked the call");
        } else
          setDriversStatus((prevState) => {
            return {
              ...prevState,
              Status: "Driver is with customer",
            };
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  let startJourney = (data) => {
    console.log(data);
    let { guzoId } = data;
    axios
      .post(PATH + "/startJourney", { token, guzoId })
      .then((Responces) => {
        console.log(Responces.data);
        setDriversStatus((prevState) => {
          return {
            ...prevState,
            result: { ...prevState.result, status: "journeyStarted" },
          };
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [CalculatedMoney, setCalculateMoney] = useState(0);
  const [JourneyStatus, setJourneyStatus] = useState({ Status: "" });
  useEffect(() => {
    if (JourneyStatus.Status == "") return;
    let { startLat, startLng } = JourneyStatus;
    const endLat = lat;
    const endLng = lng;

    // Create a DirectionsService object
    const directionsService = new window.google.maps.DirectionsService();
    // Setup the request
    const request = {
      origin: new window.google.maps.LatLng(startLat, startLng),
      destination: new window.google.maps.LatLng(endLat, endLng),
      travelMode: window.google.maps.TravelMode.DRIVING, // Specify the travel mode, e.g., DRIVING, WALKING, TRANSIT
    };

    // Call the DirectionsService API to get the directions
    directionsService.route(request, (response, status) => {
      if (status === window.google.maps.DirectionsStatus.OK) {
        // Extract the distance value in meters
        const distanceInMeters = response.routes[0].legs[0].distance.value;

        // Convert meters to kilometers
        const distanceInKilometers = distanceInMeters / 1000;

        // Use the distance value as needed
        console.log("Distance:", distanceInKilometers, "km");
        setCalculateMoney(distanceInKilometers * 15 + 700);
      } else {
        console.error("Unable to calculate the distance. Status:", status);
      }
    });
  }, [JourneyStatus]);

  useEffect(() => {
    if (CalculatedMoney != 0) alert("Calculated Money is " + CalculatedMoney);
    return () => {};
  }, [CalculatedMoney]);

  let arrivedIndestination = async (Data) => {
    console.log("arrivedIndestination", Data);
    let { guzoId } = Data;
    axios
      .post(PATH + "/arrivedIndestination", { token, guzoId })
      .then(async (Responces) => {
        console.log(Responces.data);
        let data = Responces.data.data;
        if (data == "journeyEnded") {
          alert("Thank you you are arived in destination.");
          // Assuming you have latitude and longitude coordinates for the start and end locations

          const startLat = Data.passangersLAT;
          const startLng = Data.passangersLNG;
          // await getCurrentLocation();
          setJourneyStatus({ Status: "journeyEnded", startLat, startLng });
        }
        index = 0;
        checkDriversStatus("from arrivedIndestination");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  let cancelJourneyByDriver = () => {
    console.log("DriversStatus", DriversStatus);
    // return;
    console.log("cancelJourneyByDriver");
    let { guzoId } = DriversStatus.result;
    // return console.log("guzoId", guzoId);
    axios
      .post(PATH + "/cancelJourneyByDriver", { token, guzoId })
      .then((Responces) => {
        console.log(Responces.data);
        let { data } = Responces.data;
        if (data == "updated successfully") {
          setDriversStatus({ Status: "looking for customer..." });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  let verifyCustomerTimeOut = "";
  let verifyIfCustomerIsNotWithOtherDriver = async (data) => {
    data.token = token;
    await axios
      .post(PATH + "/verifyIfCustomerIsNotWithOtherDriver", { data })
      .then((Responces) => {
        console.log(Responces.data);
        let { Message, results } = Responces.data;
        if (Message == "it is cancelled by customer.") {
          alert(
            "sorry, the customer cancelled this connection. So please wait other customers. Thank you."
          );
          clearTimeout(verifyCustomerTimeOut);
          startLookingForTaxi();
          return;
        }
        if (Message == "it is connected with other driver") {
          alert(
            "Sorry, this customer is connected with other driver. please wait other driver and pick call immideately.Thank you."
          );
          clearTimeout(verifyCustomerTimeOut);
          startLookingForTaxi();
          return;
        }
        if (Message == "This journey is canceled by system") {
          alert(Message);
          clearTimeout(verifyCustomerTimeOut);
          startLookingForTaxi();
          return;
        }
        let { status } = results[0];
        console.log("status", status);
        if (status == "requestedByPassenger") {
        } else if (status == "acceptedByDriver") {
        } else if (status == "journeyStarted") {
          clearTimeout(verifyCustomerTimeOut);
          index = 3;
          return;
        } else if (status == "canceledByPassenger") {
          alert("Sorry, customer cancelled this connection");
          startLookingForTaxi();
          return;
        } else if (status == "canceledByDriver") {
          alert("you have canceled this call");
          startLookingForTaxi();
          index = 2;
          return;
        } else {
          alert("Sorry, Unknown error occured.");
          startLookingForTaxi();
          return;
        }

        if (index == 2)
          verifyCustomerTimeOut = setTimeout(() => {
            verifyIfCustomerIsNotWithOtherDriver(data);
          }, 1000);
        // return;
        // if (Message == "it can connect with you") {
        //   // console.log("gggggggggg");
        // } else if (Message == "connected with other driver") {

        //   return;
        // }

        // console.log(Responces.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  let startLookingForTaxi = async () => {
    // clearInterval(statusInterval);
    index = 2;
    setDriversStatus({ Status: "looking for customer..." });
    await checkIfPassangerRequestedTaxi();
  };
  let userProfile = localStorage.getItem("userProfile");
  // alert("userProfile" + userProfile);
  if (userProfile == "" || !userProfile) {
    // alert("Dear cusomer please login first");
    localStorage.clear();
    Navigate("/login");
  }
  useEffect(() => {
    index = 0;
    checkDriversStatus();
  }, []);

  return (
    <div>
      {/* <LoadMap /> */}
      <MySnackBar />
      <Box
        sx={{
          position: "absolute",
          bottom: "60px",
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: 1,
          backgroundColor: "white",
          padding: "15px 30px",
          width: "100%",
          maxWidth: "280px",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Box>
            <h4>
              Dear
              {" " +
                JSON.parse(localStorage.getItem("userProfile"))
                  ?.DriversFullName +
                " "}
              welcome to Penta orders
            </h4>
          </Box>
          <div>
            <DropdownMenu index={index} />
          </div>
        </Box>

        {openSnackBar.Message != "Login success" && openSnackBar.Message}
        {DriversStatus.Status == "Waitting..." ? (
          <Box sx={{ backgroundColor: "white" }}>{DriversStatus.Status}</Box>
        ) : DriversStatus.Status == "Driver can start job" ? (
          <>
            <Button
              sx={{ width: "250px" }}
              onClick={startLookingForTaxi}
              variant="contained"
            >
              {console.log("DriversStatus", DriversStatus)}
              Start
            </Button>
          </>
        ) : DriversStatus.Status == "looking for customer..." ? (
          <>
            <h3 style={{ width: "220px", margin: "10px auto" }}>
              {DriversStatus.Status}
            </h3>
            <Button
              onClick={() => {
                // clearTimeout(driversTimout);
                index = 0;
                setDriversStatus({ Status: "Driver can start job" });
              }}
              variant="contained"
              fullWidth
              color="warning"
            >
              Cancel
            </Button>
          </>
        ) : DriversStatus.Status == "customer is available" ? (
          <Box>
            <h3>Passanger's Name : {DriversStatus?.result?.fullName}</h3>
            <h3>
              Standing Point:
              {
                JSON.parse(
                  DriversStatus?.result?.passangersStandingAndDestination
                )?.standingPoint
              }
            </h3>
            <h3>
              Destination Point:
              {
                JSON.parse(
                  DriversStatus?.result?.passangersStandingAndDestination
                )?.destinationPoint
              }
            </h3>
            <h4>
              Passangers Phone :
              <a href={"tel:" + DriversStatus.result.phoneNumber}>
                {DriversStatus.result.phoneNumber}
              </a>
            </h4>
            <Box sx={{}}>
              <Button
                onClick={() => {
                  acceptCustomersCall(DriversStatus.result);
                }}
                variant="contained"
              >
                Accept
              </Button>
              &nbsp;
              <Button
                onClick={() => {
                  setDriversStatus({ Status: "Driver can start job" });
                }}
                variant="contained"
                color="error"
              >
                Reject
              </Button>
            </Box>
          </Box>
        ) : DriversStatus.Status == "Driver is with customer" ? (
          <Box>
            {DriversStatus?.result?.status == "journeyStarted" ? (
              <Box>
                You are taking customer to destination. Have a nice
                journey.Thank you.
              </Box>
            ) : (
              <Box>
                <div>You are going to customer</div>

                <h4>Name:-{DriversStatus?.result?.fullName}</h4>
                <h4>
                  Phone{" : "}
                  <a href={"tel:" + DriversStatus?.result?.phoneNumber}>
                    {DriversStatus?.result?.phoneNumber}
                  </a>
                </h4>
                <h4>Passangers Journey Direction </h4>
                <h4>
                  FROM{" "}
                  {
                    JSON.parse(
                      DriversStatus?.result?.passangersStandingAndDestination
                    )?.standingPoint
                  }{" "}
                  TO{" "}
                  {
                    JSON.parse(
                      DriversStatus?.result?.passangersStandingAndDestination
                    )?.destinationPoint
                  }
                </h4>
              </Box>
            )}
            {DriversStatus?.result?.status == "journeyStarted" ? (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <h3 color="error">Are you Arrived in your destination?</h3>
                <Button
                  onClick={() => arrivedIndestination(DriversStatus?.result)}
                  variant="contained"
                >
                  YES
                </Button>
              </Box>
            ) : (
              <Box sx={{ display: "flex" }}>
                <Button
                  onClick={() => {
                    index = 3;
                    startJourney(DriversStatus?.result);
                  }}
                  variant="contained"
                >
                  Start Journey
                </Button>
                &nbsp;
                <Button
                  onClick={cancelJourneyByDriver}
                  variant="contained"
                  color="error"
                >
                  Cancel
                </Button>
              </Box>
            )}
          </Box>
        ) : (
          "error "
        )}
      </Box>
    </div>
  );
};
export default DriversMapContainer;
