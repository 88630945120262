import React from "react";
import DriversMapContainer from "./DriversMapContainer";

function DriversPanel() {
  return (
    <div>
      <DriversMapContainer />
    </div>
  );
}

export default DriversPanel;
