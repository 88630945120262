import React, { useState, useEffect } from "react";
import { TextField, Button, Box, Paper } from "@mui/material";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import MySnackBar from "./MySnackBar";
import { ConsumeContext } from "./StateProvider";
let PATH = process.env.REACT_APP_PATH;

const Login = () => {
  let { openSnackBar, setopenSnackBar } = ConsumeContext();
  let Navigate = useNavigate();
  let userToken = localStorage.getItem("token");
  // alert(token);
  useEffect(() => {
    if (userToken != null) Navigate("/");
  }, [userToken]);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [Processing, setProcessing] = useState(false);
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setProcessing(true);
      const response = await axios
        .post(PATH + "/loginDrivers", {
          username,
          password,
        })
        .then((Responces) => {
          let { token, data, userProfile } = Responces.data;
          console.log(Responces.data);
          setProcessing(false);
          // return;
          if (data == `Phone or email not exists`) {
            alert(data);
          } else if (data == "login success") {
            // alert(data);
            setopenSnackBar({ Open: true, Message: "Login success" });
            localStorage.setItem("userProfile", JSON.stringify(userProfile[0]));
            localStorage.setItem("token", token);
            // Navigate("/");
          } else {
            console.log("unknown error in login");
          }
        })
        .catch((error) => {
          console.log(error);
        });

      // Reset form fields
      setUsername("");
      setPassword("");
    } catch (error) {
      setProcessing(false);
      console.error("Error logging in:", error);
    }
  };

  return (
    <Box
      sx={{
        position: "absolute",
        bottom: "60px",
        left: "50%",
        transform: "translateX(-50%)",
        zIndex: 1,
        backgroundColor: "white",
        padding: "15px 30px",
        width: "100%",
        maxWidth: "280px",
      }}
    >
      <MySnackBar />
      <form
        style={{ display: "flex", flexDirection: "column" }}
        onSubmit={handleSubmit}
      >
        {openSnackBar.Message}
        <h3>Login form to drivers</h3>
        <TextField
          label="Phone or Email"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          fullWidth
          margin="normal"
          required
        />
        <Box>
          <Link style={{ textDecoration: "none" }} to="/register">
            Don't u have account? register here.
          </Link>
        </Box>
        <Box sx={{ mt: 2 }}>
          {!Processing ? (
            <Button fullWidth variant="contained" color="primary" type="submit">
              Login
            </Button>
          ) : (
            <Button disabled variant="contained">
              Processing ....
              <CircularProgress />
            </Button>
          )}
        </Box>
      </form>
    </Box>
  );
};

export default Login;
